import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import {getCMSConfig, getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import BannerContent from "../text/banner-content";
import React, {useCallback, useRef, useState} from "react";
import useIntersectionObserver from "react-storefront/hooks/useIntersectionObserver";

const useStyles = makeStyles({
	wrapper: {
		position: 'relative',
		backgroundColor: '#212121'
	},
	content: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		zIndex: 1,
		padding: '150px 0',
		[theme.breakpoints.up('md')]: {
			padding: '300px 0'
		}
	},
	media: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: '100%',
		height: '100%',
		border: 'none',
		objectFit: 'cover',
		backgroundAttachment: 'fixed',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	[theme.breakpoints.down('md')]: {
		content: {
			'& .pre-heading--thin': {
				margin: 0,
				'& span': {
					fontSize: '36px !important'
				}
			}
		},
		media: {
			backgroundAttachment: 'scroll'
		}
	}
}, {name: 'Parallax'});

export default function Parallax({block, slots}) {
	const CMSSettings = getCMSSettings(block);
	const classes = useStyles();
	const text = getCMSSlot('overlay', slots);
	const image = getCMSSlot('image', slots);
	const [visible, setVisible] = useState(false);

	const aspectRatio = ((9 / 16) * 100) + '%';
	const ref = useRef(null);

	const onVisibilityChange = useCallback((scrolledIntoView) => {
		if (scrolledIntoView && !visible) {
			setVisible(true);
		}
	}, []);

	useIntersectionObserver(() => ref, onVisibilityChange, []);

	function getOpacity() {
		return getCMSConfig('opacity', image.config);
	}

	function getMedia() {
		let media = image.data.media;

		if (media.metaData !== null) {
			let src = media.url;

			return <div className={classes.media} style={{
				display: 'flex',
				opacity: getOpacity(),
				backgroundImage: 'linear-gradient(rgba(0, 0, 0, ' + (1 - getOpacity()) + '), rgba(0, 0, 0, ' + (1 - getOpacity()) + ')), url(' + (visible ? src : '') + ')'
			}}/>;
		} else {
			let src = {
				lazySrc: media.url
			};

			if (visible) {
				src.src = src.lazySrc;
				delete src.lazySrc;
			}

			return <div style={{display: 'flex', opacity: getOpacity()}} ref={ref}><video {...src} className={classes.video} autoPlay="autoplay" loop muted/></div>;
		}
	}

	return (
		<Grid style={{
				...CMSSettings.styles,
			}} ref={ref} container>
			<Grid xs={12} item>
				<div className={classes.wrapper}>
					{getMedia()}
					<div className={classes.content}>
						<BannerContent content={text.data.content}/>
					</div>
				</div>
			</Grid>
		</Grid>
	);
}
