import { Grid } from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import {getCMSConfig, getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import BannerContent from "../text/banner-content";
import Image from 'next/image';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "next/link";
import {useRouter} from "next/router";
import urlNormalizer from "tools/urlNormalization";
import Slick from "react-slick";

const useStyles = makeStyles({
	wrapper: {
		padding: 0,
		overflow: 'hidden',
		'& .slick-slider': {
			width: '100%',
			padding: '0',
			overflow: 'hidden',
		},
		'& .slick-track': {
			display: 'flex',
			gap: 0,
			transform: 'translate3d(0,0,0)',
		},
		'& .slick-slide': {
			display: 'flex',
			marginRight: 15,
			'& > div': {
				width: '100%'
			}
		},
		'& .slick-list': {
			marginBottom: 25,
			maxWidth: '100%',
			transform: 'translate3d(0,0,0)'
		},
		'& .slick-arrow': {
			display: 'none !important'
		},
		[theme.breakpoints.up('md')]: {
			'& .slick-track': {
				gap: 15,
				width: '100% !important',
				maxWidth: '100vw'
			},
			'& .slick-slide': {
				marginRight: 0
			}
		},
		[theme.breakpoints.down('sm')]: {
			'& .slick-slider': {
				paddingBottom: 12
			},
			'& .slick-slide': {
				padding: 0
			},
			'& .slick-list': {
				marginRight: '20%',
				marginBottom: 0
			}
		},
	},
	grid: {},
	item: {
		[theme.breakpoints.up('xl')]: {
			margin: '-5px 0'
		}
	},
	link: {
		position: 'relative',
		background: '#212121',
		display: 'block'
	},
	imageWrapper: {
		transition: 'opacity 0.25s ease',
		'&:hover': {
			opacity: '0.85 !important'
		}
	},
	content: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: '0 20px 30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        pointerEvents: 'none',
		'&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 1,
            width: '100%',
            height: '50%',
            backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0.0))'
        },
		'& h2': {
			fontSize: '28px !important'
		},
		'& p': {
			fontSize: 14
		}
	},
}, {name: 'NewportBarkerFour'});

export default function NewportBarkerFour({block, slots, category}) {
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);
	const router = useRouter();
	const isIPad = useMediaQuery(theme.breakpoints.down('lg'));
	const leftText = getCMSSlot('left-overlay', slots);
	const leftImage = getCMSSlot('left', slots);
	const leftMiddleText = getCMSSlot('left-middle-overlay', slots);
	const leftMiddleImage = getCMSSlot('left-middle', slots);
	const rightMiddleText = getCMSSlot('right-middle-overlay', slots);
	const rightMiddleImage = getCMSSlot('right-middle', slots);
	const rightText = getCMSSlot('right-overlay', slots);
	const rightImage = getCMSSlot('right', slots);

	const settings = {
		dots: false,
		infinite: true,
		center: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: false,
		prevArrow: false,
		responsive: [
			{
				breakpoint: 9999,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: theme.breakpoints.values.sm,
				settings: {
					slidesToShow: 1
				}
			}
		]
	}

	function getBannerLink(slot) {
		return slot.data.url || '#';
	}

	function getImage(slot) {
		let media = slot.data.media;

		let returnObject = {
			src: media.url
		};

		returnObject.layout = 'responsive';
		returnObject.width = media.metaData.width;
		returnObject.height = media.metaData.height;

		return returnObject;
	}

	function getOpacity(image) {
		return 1;
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	function renderBarker({ image, text }) {
		return <Grid xs={12} sm={12} md={12} className={classes.item} item>
			<Link href={urlNormalize(getBannerLink(image))}>
				<a className={classes.link}>
					<div className={classes.imageWrapper} style={{opacity: getOpacity(image)}}>
						<Image alt={category.metaTitle || category.name || 'Newport image'} {...getImage(image)} className={[classes.image, 'image'].join(' ')} unoptimized/>
					</div>
					<div className={classes.content}>
						<BannerContent content={text.data.content}/>
					</div>
				</a>
			</Link>
		</Grid>
	}

	function getSlots() {
		return [
			{
				image: leftImage,
				text: leftText
			},
			{
				image: leftMiddleImage,
				text: leftMiddleText
			},
			{
				image: rightMiddleImage,
				text: rightMiddleText
			},
			{
				image: rightImage,
				text: rightText
			}
		]
	}

	return (
		<div className={classes.wrapper}>
			<Grid spacing={0} style={{
				...CMSSettings.styles,
				width: 'auto'
			}} container>
				<Slick {...settings}>
					{getSlots().map(({ image, text }) => {
						return image?.data?.media ? renderBarker({ image, text }) : '';
					})}
				</Slick>
			</Grid>
		</div>
	);
}
