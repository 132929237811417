import {Accordion, AccordionSummary, AccordionDetails, List, ListItem, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import {getCMSSettings} from "tools/cmsBlockSettings";
import theme from "../../../theme";
import Link from "next/link";
import {useEffect, useState} from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => {
	return {
		wrapper: {
			[theme.breakpoints.down('sm')]: {
				marginTop: '15px !important'
			}
		},
		title: {
			color: '#333',
			fontSize: 13,
			fontStyle: 'italic',
		},
		list: {
		},
		listItem: {
			padding: 0,
			fontSize: 13,
			lineHeight: '25px',
			'& a': {
				textDecoration: 'none',
				color: '#999',
				borderBottom: '1px solid transparent',
				transition: 'all 0.25s ease',
				'&:hover': {
					paddingLeft: 3,
					color: '#666',
					borderBottom: '1px solid #ccc'
				}
			}
		},
		accordion: {
			boxShadow: 'none',
			'&:before': {
				display: 'none'
			},
			[theme.breakpoints.down('sm')]: {
				marginBottom: 10,
				backgroundColor: 'white',
				boxShadow: theme.borderShadow
			}
		},
		accordionExpanded: {
			[theme.breakpoints.up('sm')]: {
				margin: '0 !important'
			}
		},
		summary: {
			[theme.breakpoints.up('sm')]: {
				minHeight: '0 !important',
				marginBottom: '0 !important'
			}
		},
		summaryExpanded: {
			[theme.breakpoints.up('sm')]: {
				margin: '0 !important'
			}
		},
		summaryIcon: {
			[theme.breakpoints.up('sm')]: {
				display: 'none'
			}
		}
	}
}, {name: 'CategoryNavigation'});

export default function CategoryNavigation({block, category, categoryMenu}) {
	const customFields = category?.translated?.customFields || category?.customFields;
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);
	const [state, setState] = useState(false);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
	const menuItemIds = customFields?.menu_items ? Object.keys(customFields.menu_items) : [];
	const menuItems = customFields?.menu_items ? customFields.menu_items : [];

	useEffect(() => {
		if(isDesktop) {
			setState({delivery: true, care: true});
		}
	}, [isDesktop]);

	function getMenuTitle() {
		const breadcrumbs = JSON.parse(JSON.stringify(category.breadcrumb));
		const lastBreadcrumb = breadcrumbs.pop();

		return breadcrumbs.length > 0 ? breadcrumbs.pop() : lastBreadcrumb;
	}

	function renderListItem(itemId, item) {
		let menuItem = item;

		if(itemId) {
			if (Object.keys(menuItems).indexOf(itemId) < 0) {
				return '';
			}

			menuItem = menuItems[itemId];
		}

		const itemUrl = getUrl(menuItem);

		return (
			<ListItem className={classes.listItem} key={itemId} disableGutters>
				<Link href={itemUrl}>{menuItem?.name || menuItem.translated?.name}</Link>
			</ListItem>
		);
	}

	function getUrl(item) {
		if(item.url) {
			return item.url;
		}

		return item.type === 'link' ? (item.linkType === 'external' ? item.externalLink : '/' + item.internalLink) : item.seoUrls && item.seoUrls.length > 0 ? '/' + item.seoUrls[0].seoPathInfo : '/navigation/' + item.id;
	}

	function toggleAccordionState() {
		setState(!state);
	}

	return (
		<div className={classes.wrapper} style={{
				...CMSSettings.styles,
			}}>
			<Accordion classes={{root: classes.accordion, expanded: classes.accordionExpanded}} square={true} expanded={state} onChange={() => toggleAccordionState()}>
				<AccordionSummary classes={{content: classes.summary, expanded: classes.summaryExpanded}} expandIcon={<ExpandMore classes={{root: classes.summaryIcon}} />}>
					<Typography className={classes.title}>{getMenuTitle()}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<List className={classes.list} disablePadding>
						{categoryMenu ? categoryMenu.map((item) => {
							return renderListItem(null, item);
						}) : menuItemIds.map((itemId) => {
							return renderListItem(itemId);
						})}
					</List>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
