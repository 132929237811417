import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import {getCMSSettings, getCMSSlot, getCMSConfig} from "tools/cmsBlockSettings";
import Image from 'next/image';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "next/link";
import React, {useEffect, useState} from "react";
import {useRouter} from "next/router";
import urlNormalizer from "tools/urlNormalization";
import CategoryMenu from "../menu/category-menu";
import CmsButton from "../buttons/cms-button";

const useStyles = makeStyles({
	wrapper: {
		display: 'flex',
		height: '650px',
		position: 'relative',
		overflow: 'hidden',
		'&.hide': {
			display: 'none'
		},
		'&.bg-wedding-flower-bottom': {
			overflow: 'visible',
			'&:before': {
				content: '""',
				position: 'absolute',
				left: 0,
				right: 0,
				bottom: -41,
				width: '100%',
				height: 123,
				zIndex: 1,
				backgroundImage: 'url(https://cdn.newporthome.eu/media/b2/d8/8a/1724327114/wedding-top-desktop-flower-small.png)',
				backgroundSize: 'auto 100%',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center bottom',
				[theme.breakpoints.down('xs')]: {
					left: '10%',
					bottom: 'calc(80vw * (41/155) * -0.33)',
					width: '100%',
					maxWidth: '80%',
					height: '100%',
					maxHeight: '20vw',
					backgroundSize: '100% auto',
				}
			}
		},
	},
	link: {
		display: 'block',
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		zIndex: 10
	},
	image: {
		width: '100%',
		opacity: 0,
		transition: 'opacity 1s ease',
		[theme.breakpoints.up('md')]: {
			opacity: 1
		},
		'& > div': {
			position: 'static !important',
			display: 'flex !important'
		},
		'& img': {
			objectFit: 'cover'
		},
		[theme.breakpoints.up('md')]: {
			'&.mobile': {
				display: 'none'
			}
		}
	},
	desktop: {

	},
	mobile: {
		display: 'grid',
		gridTemplateRows: 'auto',
		gridTemplateColumns: 'auto',
		width: '100%',
		height: '100%',
		'& > *': {
			gridColumn: '1 / 2',
			gridRow: '1 / 2'
		},
		aspectRatio: '1 / 1',
		[theme.breakpoints.up('sm')]: {
			height: '650px !important',
		}
	},
	video: {
		display: 'block',
		width: '100%',
		'.cover &': {
			objectFit: 'cover'
		},
		[theme.breakpoints.down('xs')]: {
			minHeight: '100vw',
		},
	},
	svgTitle: {

	},
	content: {
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 20,
		pointerEvents: 'none',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		padding: '0 40px',
		[theme.breakpoints.down('sm')]: {
			padding: '0 30px'
		},
	},
	preTitle: {
		margin: '0 0 20px 0',
		color: '#fff',
    	fontSize: 13,
    	textAlign: 'center',
    	fontWeight: 500,
    	marginBottom: 15,
    	letterSpacing: 2.25,
		'&:empty': {
			display: 'none'
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: 5
		}
	},
	title: {
		margin: 0,
		color: 'white',
		textAlign: 'center',
		lineHeight: 1,
		animation: '$fadeInAndDown 0.86s ease-out',
		fontSize: 72,
		fontWeight: 400,
		fontFamily: 'Chronicle, serif',
		'&.chronicle': {
			fontFamily: 'Chronicle, serif',
			fontWeight: 400
		},
		'&.didot': {
			fontFamily: 'Chronicle, serif',
			fontWeight: 400
		},
		'&.fenice': {
			fontFamily: 'Chronicle, serif',
			fontWeight: 400
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: 36
		}
	},
	text: {
		marginTop: 10,
		fontSize: 19,
		color: 'white',
		textAlign: 'center',
		'&:empty': {
			display: 'none'
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 14,
			fontSize: 14
		}
	},
	buttonWrapper: {
		display: 'flex',
		gap: 20,
		marginTop: 22,
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		},
		'& a, & button': {
			width: '100%'
		}
	},
	'@keyframes fadeInAndDown': {
		'0%': {
			'transform': 'translateY(25px)',
			'opacity': 0,
		},
		'90%': {
			'transform': 'translateY(0)',
		},
		'100%': {
			'transform': 'translateY(0)',
			'opacity': 1,
		}
	}
}, {name: 'MainBannerNew'});

export default function MainBannerNew({block, category, slots, cmsPage, categoryMenu, breadcrumbs}) {
	const classes = useStyles();
	const router = useRouter();
	const CMSSettings = getCMSSettings(block);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
	const desktopSlider = getCMSSlot('desktop-slider', slots);
	const mobileSlider = getCMSSlot('mobile-slider', slots);
	const content = getCMSSlot('content', slots);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [active, setActive] = useState(1);

	useEffect(() => {
		setHasLoaded(true);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setActive(active + 1);
		}, 3500);
	}, [active]);

	function getBannerLink() {
		return content.translated.config.url.value || false;
	}

	function getMediaType(media) {
		return media && media.metaData !== null ? 'image' : 'video';
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	function getPreTitle() {
		let contentConfig = content.translated.config;

		return isDesktop ? contentConfig.preTitle.value : contentConfig.preTitleMobile.value || contentConfig.preTitle.value;
	}

	function getSvgTitle() {
		let contentData = content.data;
		let contentConfig = content.translated.config;

		if((isDesktop && !contentData.svgTitle) || (!isDesktop && (!contentData.svgTitleMobile || !contentData.svgTitle))) {
			return false;
		} else {
			let imgUrl = isDesktop ? contentData?.svgTitle?.url : (contentData?.svgTitleMobile?.url || contentData.svgTitle.url);
			let imgWidth = isDesktop ? (contentConfig?.svgWidth?.value || 950) : (contentConfig?.svgWidthMobile?.value || 250);
			return <img alt={getTitle(false)} src={imgUrl} style={{maxWidth: imgWidth, width: '100%'}}/>;
		}
	}

	function getTitle(html = true) {
		let contentConfig = content.translated.config;
		let title = contentConfig.title.value;
		let title2 = contentConfig.title2.value;

		if(!isDesktop && contentConfig.titleMobile.value) {
			title = contentConfig.titleMobile.value;
		}

		if(title2 || contentConfig.title2Mobile.value) {
			title2 = isDesktop ? title2 : contentConfig.title2Mobile.value || title2
		}

		const fullTitle = html ? <>
			{title}
			{
				title2 ? <>
						<br/> {title2}
					</>
					: '' }
		</> : `${title}${title2 ? ' ' + title2 : ''}`

		return fullTitle
	}

	function getText() {
		let contentConfig = content.translated.config;

		return isDesktop ? contentConfig.text.value : contentConfig.textMobile.value || contentConfig.text.value;
	}

	function getFontFamily() {
		let contentConfig = content.translated.config;

		return contentConfig.textFont.value;
	}

	function getFontSize() {
		let contentConfig = content.translated.config;

		return isDesktop ? contentConfig.textSize.value : contentConfig.textSizeMobile.value;
	}

	function getSliderItems(sliderItems, config) {
		let imageCount = sliderItems.length;

		return sliderItems.map((image, index) => {
			const type = getMediaType(image.media);

			if(type === 'image') {
				let imageProps = {
					src: image.media.url,
					alt: category.metaTitle || category.name || 'Newport image'
				}

				if (config.displayMode.value === 'cover') {
					imageProps.layout = 'fill';
					imageProps.objectFit = 'cover';
				} else {
					imageProps.width = image.media.metaData.width;
					imageProps.height = image.media.metaData.height;
				}

				return (
					<div key={index} style={{opacity: imageCount === 1 || index === active % imageCount ? getCMSConfig('opacity', config) : 0}}
						 className={[classes.image, imageCount === 1 || index === active % imageCount ? 'active' : ''].join(' ')}>
						<Image
							{...imageProps}
							key={index}
							priority={index === 0}
							loading={'eager'}
							unoptimized
						/>
					</div>
				);
			} else if(image.media) {
				let url = image.media.url;
				let videoProps = {};

				if(!hasLoaded) {
					videoProps.lazysrc = url;
				} else {
					videoProps.src = url;
				}

				return (
					<div style={{
						display: 'flex',
						position: 'relative',
						width: '100%',
						opacity: getCMSConfig('opacity', config) || 1
					}} className={config.displayMode.value} key={index}>
						<video {...videoProps} className={classes.video} autoPlay playsInline loop muted/>
					</div>
				);
			}
		});
	}

	function getCategoryMenu() {
		const menuItems = category.translated?.customFields ? category.translated?.customFields?.menu_items : false;

		if(!menuItems || menuItems.length < 1) {
			return [];
		}

		return Object.keys(menuItems).map((itemKey, key) => menuItems[itemKey]);
	}

	function hasButtons() {
		const button1 = getCMSSlot('button1', slots);
		const button2 = getCMSSlot('button2', slots);

		return button1.config.title.value !== '' || button2.config.title.value !== '';
	}

	return (
		<>
			<div className={`${classes.wrapper} ${CMSSettings.class || ''} ${isDesktop ? classes.desktop : classes.mobile }`} style={{
					marginTop: CMSSettings.styles.marginTop,
					height: isDesktop ? (desktopSlider.translated.config.displayMode.value === 'cover' ? desktopSlider.translated.config.minHeight.value : 'auto') : (mobileSlider.translated.config.displayMode.value === 'cover' ?  mobileSlider.translated.config.minHeight.value : 'auto'),
					width: '100%',
					background: CMSSettings.styles.backgroundColor || '#212121',
				}}>

				{getBannerLink() ? <Link href={urlNormalize(getBannerLink())}><a className={classes.link}/></Link> : ''}

				{isDesktop ? getSliderItems(desktopSlider.data.sliderItems, desktopSlider.translated.config) : getSliderItems(mobileSlider.data.sliderItems, mobileSlider.translated.config)}

				<div className={[classes.content, getSvgTitle() ? classes.svgTitle : ''].join(' ')}>
					{getSvgTitle() ?
						<>
							{getSvgTitle()}
							<div className={classes.text} dangerouslySetInnerHTML={{__html: getText()}} />
						</> : <>
							<p className={classes.preTitle}>{getPreTitle()}</p>
							<h1 className={[classes.title, getFontFamily()].join(' ')} style={{fontSize: getFontSize()}}>{getTitle()}</h1>
							<div className={classes.text} dangerouslySetInnerHTML={{__html: getText()}} />
						</>
					}

					{hasButtons() ? <div className={classes.buttonWrapper}>
						<CmsButton block={{ }} slots={slots} slotName={'button1'} isInBanner={true}/>
						<CmsButton block={{ }} slots={slots} slotName={'button2'} isInBanner={true}/>
					</div> : ''}
				</div>
			</div>

			{cmsPage.type === 'landingpage' ? <CategoryMenu
				category={category}
				categoryMenu={categoryMenu || getCategoryMenu()}
				block={block}
				slots={slots}
				breadcrumbs={breadcrumbs}
				forceButton={true}
				hideSeeAll={true}
				cmsPage={cmsPage}
			/> : ''}
		</>
	);
}
