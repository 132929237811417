import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import Slider from "react-slick";
import ChevronRight from "../../../../public/images/icons/chevron_right_light.svg";
import ChevronLeft from "../../../../public/images/icons/chevron_left_light.svg";
import {getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import Image from 'next/image';

const useStyles = makeStyles({
	wrapper: {
		[theme.breakpoints.down('xs')]: {
			marginLeft: -15,
			marginRight: -15,
		}
	},
	slider: {
		position: 'relative',
		width: '100%',
		paddingBottom: 20,
		'& .slick-track': {
			display: 'flex',
			height: '100%',
			alignItems: 'center'
		},
		'& .slick-slide': {
			display: 'flex',
			width: '100%',
			maxWidth: 1000,
			'& > div': {
				width: '100%',
				margin: '0 20px'
			}
		},
		'& .slick-list': {
			maxWidth: '100vw',
			overflow: 'hidden'
		},
		[theme.breakpoints.down('sm')]: {
			'&.slick-slider': {
				paddingBottom: 20
			},
			'& .slick-slide': {
				padding: 0,
				'& > div': {
					margin: '0 !important'
				}
			},
			'& .slick-list': {
				marginBottom: 20
			}
		},
	},
	image: {
		[theme.breakpoints.down('sm')]: {
			width: '100% !important',
			height: '100%',
		}
	},
	arrow: {
		position: 'absolute',
		bottom: 0,
		zIndex: 10,
		minWidth: 0,
		[theme.breakpoints.up('sm')]: {
			top: '50%',
			bottom: 'auto',
			width: 'auto',
			padding: 10,
			transform: 'translateY(-50%)',
			backgroundColor: 'white',
			boxShadow: theme.boxShadow[1],
			borderRadius: '50%',
			'&:hover': {
				backgroundColor: 'white',
				boxShadow: theme.boxShadow[2],
			}
		}
	},
	arrowRight: {
		right: 0,
		[theme.breakpoints.up('sm')]: {
			transform: 'translateY(-20px)',
			right: 15
		}
	},
	arrowLeft: {
		left: 0,
		[theme.breakpoints.up('sm')]: {
			transform: 'translateY(-20px)',
			left: 15
		}
	},
	arrowIcon: {
		display: 'block'
	},
	slickDots: {
		display: 'flex !important',
		justifyContent: 'center',
		padding: 0,
		listStyle: 'none',
		'& li': {
			width: 6,
			height: 6,
			margin: '2px 4px',
			borderRadius: '50%',
			backgroundColor: 'rgba(0,0,0,0.25)',
			'&.slick-active': {
				width: 6,
				height: 6,
				backgroundColor: 'rgba(0,0,0,0.75)'
			},
			'& button': {
				display: 'none'
			}
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 0,
			marginBottom: -4
		}
	}
}, {name: 'ImageSlider'});

export default function SWImageSlider({block, slots}) {
	const CMSSettings = getCMSSettings(block);
	const classes = useStyles();
	const slider = getCMSSlot('imageSlider', slots);

	const settings = {
		dots: true,
		infinite: true,
		speed: 200,
		slidesToShow: 1,
		centerMode: true,
		variableWidth: true,
		nextArrow: <Button classes={{root: [classes.arrow, classes.arrowRight].join(' ')}}><ChevronRight width={28} className={classes.arrowIcon}/></Button>,
		prevArrow: <Button classes={{root: [classes.arrow, classes.arrowLeft].join(' ')}}><ChevronLeft width={28} className={classes.arrowIcon}/></Button>,
		dotsClass: classes.slickDots,
		responsive: [
			{
				breakpoint: 99999,
				settings: {
					slidesToShow: 1,
					centerMode: true,
					variableWidth: true,
				}
			},
			{
				breakpoint: theme.breakpoints.values.md,
				settings: {
					slidesToShow: 1,
					centerMode: true,
					variableWidth: true,
				}
			},
			{
				breakpoint: theme.breakpoints.values.sm,
				settings: {
					slidesToShow: 1,
					centerMode: false,
					variableWidth: false,
				}
			}
		]
	};

	function getImage(image) {
		let media = image.media;

		let imageObject = {
			src: media.url
		};

		imageObject.layout = 'responsive';
		imageObject.width = media.metaData.width;
		imageObject.height = media.metaData.height;

		return <Image {...imageObject} className={[classes.image, 'image'].join(' ')} unoptimized/>
	}

	return (
		<div className={classes.wrapper} style={{
				...CMSSettings.styles,
			}} container>
			<Slider className={classes.slider} {...settings}>
				{slider.data.sliderItems ? slider.data.sliderItems.map((image) => getImage(image)) : ''}
			</Slider>
		</div>
	);
}
