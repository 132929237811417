import {Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {getCMSSettings} from "tools/cmsBlockSettings";
import Image from 'next/image';
import React from "react";
import Link from "next/link";
import theme from "../../../theme";
import urlNormalizer from "tools/urlNormalization";
import {useRouter} from "next/router";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import Slick from "react-slick";

const useStyles = makeStyles({
	grid: {},
	wrapper: {
		display: 'flex',
		position: 'relative',
		gap: 40,
		'& .regular': {
			display: 'flex',
			flexWrap: 'wrap',
			width: '100%',
			gap: 20
		},
		'& .slick-slider': {
			width: '100%',
			padding: '0',
			overflow: 'hidden',
		},
		'& .slick-track': {
			display: 'flex',
			gap: 20,
			transform: 'translate3d(0,0,0)'
		},
		'& .slick-slide': {
			display: 'flex',
			'& > div': {
				display: 'flex',
				width: '100%'
			}
		},
		'& .slick-list': {
			marginBottom: 25,
			maxWidth: '100vw',
			transform: 'translate3d(0,0,0)'
		},
		'& .slick-arrow': {
			display: 'none !important'
		},
		[theme.breakpoints.down('sm')]: {
			'& .slick-track': {
				gap: 0,
			},
			'& .slick-slider': {
				paddingBottom: 12
			},
			'& .slick-slide': {
				padding: 0,
				marginRight: 15,
			},
			'& .slick-list': {
				marginRight: '20%',
				marginBottom: 0
			}
		}
	},
	noSliderGrid: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		gap: 20,
		'& $item': {
			width: 'calc(50% - 10px)',
			[theme.breakpoints.up('md')]: {
				width: 'calc(33.333% - 13.333px)'
			}
		},
		'& $content': {
			padding: '20px 12px 12px'
		},
		'& $title': {
			fontSize: 20
		},
		'& $text': {
			fontSize: 14
		}
	},
	imageWrapper: {
		position: 'relative',
		display: 'block',
		width: '100%',
		transition: 'opacity 0.25s ease',
		background: '#212121',
		'&:hover': {
			opacity: 0.9
		},
		'.small-image &': {
			maxWidth: 108
		}
	},
	content: {
		padding: '25px 20px 20px',
		backgroundColor: 'white',
		textAlign: 'center'
	},
	description: {
		display: 'none',
		margin: 0,
		fontSize: 12,
		fontWeight: 400,
		color: '#999',
		textTransform: 'uppercase',
		letterSpacing: 2
	},
	title: {
		margin: 0,
		fontWeight: 400,
		fontSize: 24,
		color: '#222',
		fontFamily: 'Chronicle, serif',
		'.small-title &': {
			fontFamily: 'Futura',
			fontSize: 14
		}
	},
	text: {
		margin: 0,
		marginTop: 6,
		fontSize: 15,
		fontWeight: 400,
		color: '#444',
		'& p': {
			margin: 0
		}
	},
	item: {
		display: 'flex',
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 'calc(50% - 40px)'
		},
		[theme.breakpoints.up('md')]: {
			width: 'calc(33.333% - 13.333px)',
			'.full-width &': {
				width: 'calc(16.666% - 20px)',
			}
		}
	},
	itemLink: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		color: 'inherit',
		textDecoration: 'none'
	},
	arrow: {
		position: 'absolute',
		bottom: 0,
		zIndex: 10,
		minWidth: 0,
		backgroundColor: 'white',
		boxShadow: '3px 3px 3px rgba(50, 50, 93, 0.04)'
	},
	arrowRight: {
		right: 0,
	},
	arrowLeft: {
		left: 0,
	},
	arrowIcon: {
		display: 'block'
	},
	slickDots: {
		display: 'flex !important',
		justifyContent: 'center',
		padding: 0,
		listStyle: 'none',
		'& li': {
			width: 5,
			height: 5,
			margin: '2px 4px',
			borderRadius: '50%',
			backgroundColor: 'rgba(0,0,0,0.25)',
			'&.slick-active': {
				width: 6,
				height: 6,
				backgroundColor: 'rgba(0,0,0,0.75)'
			},
			'& button': {
				display: 'none'
			}
		},
		[theme.breakpoints.down('sm')]: {
			margin: 0
		}
	}
}, {name: 'BarkerSix'});

export default function BarkerSix({block, slots, category}) {
	const router = useRouter();
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);
	const isNoSlider = block.type === 'barker-six-no-slider';

	const settings = {
		dots: false,
		infinite: true,
		center: false,
		nextArrow: <Button classes={{root: [classes.arrow, classes.arrowRight].join(' ')}}><ChevronRight classes={{root: classes.arrowIcon}}/></Button>,
		prevArrow: <Button classes={{root: [classes.arrow, classes.arrowLeft].join(' ')}}><ChevronLeft classes={{root: classes.arrowIcon}}/></Button>,
		dotsClass: classes.slickDots,
		responsive: [
			{
				breakpoint: 9999,
				settings: "unslick"
			},
			{
				breakpoint: theme.breakpoints.values.md,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: theme.breakpoints.values.sm,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	function getImage(slot) {
		let media = slot.data.media;

		if(!media) {
			return {
				src: ' ',
				width: 0,
				height: 0
			}
		}

		let returnObject = {
			src: media.url
		};

		returnObject.layout = 'responsive';
		returnObject.width = media.metaData.width;
		returnObject.height = media.metaData.height;

		return returnObject;
	}

	function getActiveSliders() {
		return slots.filter((slide) => {
			if(slide.type === 'display-settings') {
				return false;
			}

			return slide.config.active.value !== false;
		});
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	function renderItems(item) {
		return <div className={classes.item} key={item.blockId}>
			<Link href={urlNormalize(item.translated.config.url.value)}>
				<a className={classes.itemLink} key={item.blockId}>
					<div className={classes.imageWrapper}>
						<Image {...getImage(item)} alt={category.metaTitle || category.name || 'Newport image'}
							   className={[classes.image, 'image'].join(' ')} loading={'eager'} unoptimized/>
					</div>
					<div className={classes.content}>
						<p className={classes.description}>{item.translated.config.description.value}</p>
						<h4 className={classes.title}>{item.translated.config.title.value}</h4>
						{item.translated.config.text?.value ? <div className={classes.text}
																   dangerouslySetInnerHTML={{ __html: item.translated.config.text.value }}/> : ''}
					</div>
				</a>
			</Link>
		</div>
	}

	return (
		<Grid style={{
			...CMSSettings.styles,
		}} className={[classes.grid, block.cssClass].join(' ')} container>
			<Grid xs={12} item>
				<Grid className={classes.wrapper} container>
					{isNoSlider ? <div className={classes.noSliderGrid}>{getActiveSliders().map((item) => renderItems(item))}</div> : <Slick {...settings}>
						{getActiveSliders().map((item) => renderItems(item))}
					</Slick>}
				</Grid>
			</Grid>
		</Grid>
	);
}
